import React from "react";

export default function PCISSCLogoNoGlobe(props) {
  return (
    <svg height="inherit" viewBox="47.394 271.305 1036.053 314.04" {...props}>
      <defs>
        <clipPath id="clipPath62" clipPathUnits="userSpaceOnUse">
          <path d="M0 595.28h841.89V0H0z"></path>
        </clipPath>
        <clipPath id="clipPath160" clipPathUnits="userSpaceOnUse">
          <path d="M35.546 15.642h777.04v559.086H35.546z"></path>
        </clipPath>
        <clipPath id="clipPath172" clipPathUnits="userSpaceOnUse">
          <path d="M0 595.28h841.89V0H0z"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 0 793.707)">
        <g>
          <g clipPath="url(#clipPath62)">
            <g transform="translate(416.432 285.033)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c2.072-1.326 5.053-2.354 8.251-2.354 4.772 0 7.526 2.463 7.526 6.144 0 3.317-1.908 5.289-6.762 7.106-5.88 2.136-9.506 5.226-9.506 10.252 0 5.6 4.653 9.77 11.624 9.77 3.645 0 6.353-.845 7.916-1.754l-1.291-3.799c-1.108.699-3.508 1.7-6.753 1.7-4.925 0-6.798-2.926-6.798-5.38 0-3.364 2.191-5.026 7.163-6.953 6.08-2.408 9.115-5.271 9.115-10.57 0-5.544-4.044-10.405-12.542-10.405-3.472 0-7.262 1.071-9.18 2.335L0 0"
              ></path>
            </g>
            <g transform="translate(459.874 294.857)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c.055 2.937-1.219 7.644-6.435 7.644-4.817 0-6.871-4.381-7.215-7.644zm-13.714-3.362c.11-6.353 4.098-8.961 8.796-8.961 3.373 0 5.453.572 7.189 1.282l.827-3.317c-1.645-.746-4.526-1.664-8.642-1.664-7.897 0-12.715 5.29-12.715 13.033 0 7.842 4.655 13.995 12.188 13.995 8.425 0 10.633-7.424 10.633-12.178 0-.964-.064-1.708-.163-2.236l-18.113.046"
              ></path>
            </g>
            <g transform="translate(488.665 280.28)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-1.245-.572-3.972-1.49-7.426-1.49-7.805 0-12.877 5.29-12.877 13.241 0 7.962 5.452 13.777 13.895 13.777 2.771 0 5.226-.69 6.49-1.39l-1.055-3.581c-1.118.6-2.862 1.235-5.435 1.235-5.933 0-9.151-4.425-9.151-9.823 0-5.981 3.863-9.678 8.996-9.678 2.647 0 4.417.654 5.753 1.245L0 0"
              ></path>
            </g>
            <g transform="translate(515.774 286.433)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0-2.672.073-5.025.219-7.053h-4.181l-.236 4.218h-.118c-1.172-2.081-3.962-4.808-8.552-4.808-4.053 0-8.915 2.299-8.915 11.332v15.106h4.697V4.535c0-4.917 1.555-8.278 5.772-8.278 3.154 0 5.345 2.189 6.207 4.335.263.627.427 1.48.427 2.398v15.805H0V0"
              ></path>
            </g>
            <g transform="translate(523.645 297.166)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 3.045-.046 5.654-.219 8.062H3.89l.217-5.09h.165c1.172 3.471 4.053 5.671 7.187 5.671.491 0 .874-.053 1.3-.155V4.099c-.535.055-1.017.119-1.654.119-3.326 0-5.661-2.518-6.298-5.99a13.426 13.426 0 01-.171-2.245v-13.769H-.046L0 0"
              ></path>
            </g>
            <path
              fill="#006b71"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M545.931 279.38h-4.682v25.838h4.682zm-2.447 30.59c-1.761 0-2.943 1.391-2.943 3.055 0 1.654 1.235 2.982 3.053 2.982 1.808 0 2.99-1.328 2.99-2.982 0-1.664-1.182-3.055-3.026-3.055h-.074"
            ></path>
            <g transform="translate(559.398 311.406)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0v-6.197h6.726v-3.571H0v-13.943c0-3.198.927-5.016 3.534-5.016 1.265 0 2.029.109 2.728.328l.199-3.581c-.898-.317-2.345-.645-4.162-.645-2.19 0-3.945.753-5.053 1.98-1.299 1.445-1.835 3.736-1.835 6.79v14.087h-4v3.571h4v4.761L0 0"
              ></path>
            </g>
            <g transform="translate(573.602 305.228)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l5.607-15.276c.655-1.709 1.274-3.745 1.71-5.289h.11c.471 1.544 1.016 3.525 1.645 5.387L14.205 0h4.972l-7.063-18.422c-3.361-8.869-5.661-13.414-8.859-16.232-2.336-1.981-4.591-2.781-5.763-2.997L-3.68-33.7c1.172.31 2.727 1.073 4.107 2.301 1.282 1.018 2.828 2.827 3.954 5.234.21.481.364.799.364 1.118 0 .274-.091.653-.364 1.172L-5.126 0H0"
              ></path>
            </g>
            <g transform="translate(416.432 231.65)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c2.072-1.346 5.053-2.354 8.251-2.354 4.772 0 7.526 2.463 7.526 6.153 0 3.299-1.908 5.271-6.762 7.088-5.88 2.128-9.506 5.235-9.506 10.26 0 5.6 4.653 9.762 11.624 9.762 3.645 0 6.353-.854 7.916-1.763l-1.291-3.792c-1.108.693-3.508 1.701-6.753 1.701-4.925 0-6.798-2.926-6.798-5.379 0-3.363 2.191-5.018 7.163-6.936 6.08-2.417 9.115-5.297 9.115-10.587 0-5.552-4.044-10.406-12.542-10.406-3.472 0-7.262 1.064-9.18 2.355L0 0"
              ></path>
            </g>
            <g transform="translate(448.622 258.015)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0v-6.189h6.727V-9.77H0v-13.933c0-3.208.919-5.025 3.527-5.025 1.29 0 2.044.11 2.727.327l.218-3.581c-.901-.336-2.354-.644-4.173-.644-2.19 0-3.944.744-5.071 1.971-1.271 1.435-1.827 3.744-1.827 6.78V-9.77h-3.999v3.581h3.999v4.744L0 0"
              ></path>
            </g>
            <g transform="translate(474.259 239.155)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-5.133.119-10.95-.798-10.95-5.806 0-3.108 2.026-4.481 4.371-4.481 3.418 0 5.617 2.126 6.361 4.317.165.481.218 1.009.218 1.499zm4.582-6.988c0-2.245.118-4.425.373-6.189H.748L.311-9.94H.156c-1.382-2.001-4.199-3.819-7.908-3.819-5.225 0-7.896 3.682-7.896 7.417 0 6.243 5.544 9.661 15.532 9.614v.537c0 2.118-.592 6.026-5.872 5.97-2.453 0-4.962-.689-6.789-1.917l-1.063 3.163c2.145 1.327 5.29 2.217 8.552 2.217 7.961 0 9.87-5.38 9.87-10.56v-9.67"
              ></path>
            </g>
            <g transform="translate(486.374 244.837)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 2.718-.035 4.834-.218 6.988h4.172l.282-4.225h.091c1.282 2.408 4.271 4.798 8.541 4.798 3.591 0 9.127-2.126 9.127-10.989v-15.43h-4.682v14.895c0 4.162-1.573 7.688-5.997 7.688-3.036 0-5.435-2.18-6.299-4.797-.2-.602-.309-1.455-.309-2.2v-15.586H0V0"
              ></path>
            </g>
            <g transform="translate(533.633 241.31)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 .645-.045 1.382-.219 2.026-.69 2.927-3.243 5.399-6.778 5.399-4.871 0-7.752-4.281-7.752-9.951 0-5.273 2.625-9.597 7.66-9.597 3.136 0 6.018 2.135 6.87 5.598.174.654.219 1.281.219 2.036zm4.709 22.593V-8.661c0-2.299.036-4.899.189-6.662H.691l-.217 4.481H.355c-1.426-2.89-4.525-5.072-8.743-5.072-6.244 0-11.105 5.282-11.105 13.142-.055 8.642 5.344 13.869 11.587 13.869 3.99 0 6.67-1.856 7.788-3.9H0v15.396h4.709"
              ></path>
            </g>
            <g transform="translate(559.762 239.155)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-5.135.119-10.969-.798-10.969-5.806 0-3.108 2.035-4.481 4.389-4.481 3.409 0 5.589 2.126 6.352 4.317.147.481.228 1.009.228 1.499zm4.581-6.988c0-2.245.1-4.425.381-6.189H.744L.309-9.94H.154c-1.4-2.001-4.234-3.819-7.907-3.819-5.243 0-7.915 3.682-7.915 7.417 0 6.243 5.553 9.661 15.541 9.614v.537c0 2.118-.592 6.026-5.871 5.97-2.454 0-4.971-.689-6.77-1.917l-1.083 3.163c2.146 1.327 5.31 2.217 8.543 2.217 7.97 0 9.889-5.38 9.889-10.56v-9.67"
              ></path>
            </g>
            <g transform="translate(571.922 243.765)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 3.044-.073 5.661-.209 8.069h4.116l.192-5.089h.172c1.172 3.482 4.054 5.653 7.216 5.653.464 0 .846-.036 1.281-.136V4.106c-.544.055-.998.109-1.671.109-3.29 0-5.654-2.517-6.289-5.978a14.498 14.498 0 01-.163-2.245v-13.778H-.073L0 0"
              ></path>
            </g>
            <g transform="translate(606.64 241.31)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 .645-.047 1.382-.202 2.026-.717 2.927-3.271 5.399-6.786 5.399-4.881 0-7.737-4.281-7.737-9.951 0-5.273 2.592-9.597 7.617-9.597 3.162 0 6.044 2.135 6.906 5.598.155.654.202 1.281.202 2.036zm4.688 22.593V-8.661c0-2.299.055-4.899.218-6.662H.689l-.218 4.481H.38c-1.444-2.89-4.552-5.072-8.778-5.072-6.245 0-11.079 5.282-11.079 13.142-.064 8.642 5.327 13.869 11.56 13.869 4.008 0 6.697-1.856 7.815-3.9H0v15.396h4.688"
              ></path>
            </g>
            <g transform="translate(618.498 230.731)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c1.437-.863 3.891-1.817 6.234-1.817 3.373 0 4.954 1.664 4.954 3.843 0 2.245-1.318 3.482-4.735 4.754-4.689 1.708-6.888 4.226-6.888 7.325 0 4.154 3.435 7.571 8.906 7.571 2.627 0 4.918-.683 6.325-1.601l-1.127-3.371c-1.027.6-2.881 1.51-5.299 1.51-2.763 0-4.27-1.61-4.27-3.527 0-2.181 1.507-3.154 4.853-4.426 4.436-1.655 6.789-3.907 6.789-7.797 0-4.591-3.563-7.799-9.596-7.799-2.837 0-5.464.745-7.291 1.817L0 0"
              ></path>
            </g>
            <g transform="translate(676.854 227.096)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-1.654-.854-5.116-1.699-9.496-1.699-10.151 0-17.733 6.407-17.733 18.259 0 11.323 7.635 18.903 18.796 18.903 4.425 0 7.297-.955 8.533-1.609l-1.172-3.79c-1.709.855-4.217 1.508-7.189 1.508-8.451 0-14.05-5.398-14.05-14.85 0-8.869 5.054-14.477 13.759-14.477 2.9 0 5.771.59 7.67 1.499L0 0"
              ></path>
            </g>
            <g transform="translate(692.559 228.914)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c4.489 0 7.852 4.217 7.852 10.097 0 4.371-2.19 9.87-7.753 9.87-5.505 0-7.907-5.116-7.907-10.034C-7.808 4.271-4.589 0-.056 0zm-.154-3.518c-6.953 0-12.397 5.126-12.397 13.288 0 8.652 5.708 13.713 12.815 13.713 7.417 0 12.424-5.379 12.424-13.242C12.688.637 6.025-3.518-.108-3.518h-.046"
              ></path>
            </g>
            <g transform="translate(732.82 233.022)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0-2.671.044-5.017.198-7.034h-4.143l-.283 4.218h-.089c-1.182-2.082-3.963-4.809-8.571-4.809-4.035 0-8.908 2.291-8.908 11.324v15.114h4.709V4.553c0-4.925 1.536-8.28 5.771-8.28 3.134 0 5.325 2.181 6.19 4.317.281.655.418 1.5.418 2.401v15.822H0V0"
              ></path>
            </g>
            <g transform="translate(740.608 244.837)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 2.718-.046 4.834-.2 6.988h4.142l.283-4.225h.11c1.28 2.408 4.271 4.798 8.543 4.798 3.581 0 9.124-2.126 9.124-10.989v-15.43H17.32v14.895c0 4.162-1.561 7.688-6.006 7.688-3.017 0-5.445-2.18-6.307-4.797-.192-.602-.3-1.455-.3-2.2v-15.586H0V0"
              ></path>
            </g>
            <g transform="translate(788.676 226.879)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-1.228-.582-3.936-1.49-7.416-1.49-7.797 0-12.877 5.278-12.877 13.24 0 7.943 5.432 13.769 13.913 13.769 2.744 0 5.216-.673 6.479-1.363l-1.053-3.609c-1.138.6-2.9 1.245-5.426 1.245-5.962 0-9.162-4.435-9.162-9.832 0-5.972 3.846-9.66 8.979-9.66 2.665 0 4.437.643 5.763 1.225L0 0"
              ></path>
            </g>
            <path
              fill="#006b71"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M798.862 225.987h-4.698v25.847h4.698zm-2.444 30.591c-1.772 0-2.936 1.391-2.936 3.044 0 1.665 1.229 3 3.035 3 1.81 0 2.982-1.335 2.982-3 0-1.653-1.172-3.044-3.045-3.044h-.036"
            ></path>
            <path
              fill="#006b71"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M806.669 225.987h4.699v37.916h-4.699z"
            ></path>
          </g>
        </g>
        <g>
          <g clipPath="url(#clipPath160)">
            <g transform="translate(392.549 243.163)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0l-276.381-86.892-80.622 219.236 290.15 16.294z"
              ></path>
            </g>
          </g>
        </g>
        <g>
          <g clipPath="url(#clipPath172)">
            <g transform="translate(149.085 286.715)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0 8.17-6.053 12.26-18.149 12.26h-4.399v-25.229h4.053c5.98 0 10.56 1.045 13.741 3.136C-1.581-7.726 0-4.462 0 0m34.498.399c0-5.552-1.218-10.423-3.708-14.668-2.445-4.235-5.907-7.752-10.37-10.56-4.462-2.834-9.824-4.943-16.113-6.353-6.28-1.407-13.232-2.108-20.875-2.108h-5.617v-28.237H-58.12v94.116h42.088c15.731 0 28.101-2.662 37.061-7.978C30.017 19.295 34.498 11.215 34.498.399"
              ></path>
            </g>
            <g transform="translate(272.384 225.26)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-5.525-1.053-11.778-1.581-18.785-1.581-9.934 0-18.795 1.137-26.61 3.417-7.78 2.263-14.378 5.49-19.786 9.681-5.407 4.198-9.515 9.278-12.332 15.266-2.818 6.027-4.226 12.707-4.226 20.067 0 7.279 1.499 13.915 4.499 19.921 3.008 6.008 7.242 11.134 12.705 15.415 5.452 4.271 11.968 7.57 19.53 9.897 7.534 2.309 15.859 3.48 24.93 3.48 6.997 0 13.487-.209 19.166-1.436 5.706-1.245 5.826-1.418 10.361-3.055l-.064-25.255c-3.544 2-7.552 3.654-11.96 4.981-4.408 1.308-9.143 1.981-14.169 1.981-8.116 0-14.704-2.236-19.812-6.679-5.089-4.472-7.634-10.833-7.634-19.105 0-4.289.8-8.034 2.381-11.259 1.609-3.235 3.763-5.917 6.453-8.052 2.709-2.146 5.862-3.763 9.488-4.835 3.627-1.1 7.517-1.646 11.697-1.646 4.289 0 8.506.455 12.615 1.362 4.106.911 8.488 2.228 13.159 3.964l.054-23.584C5.98 1.281 5.517 1.047 0 0"
              ></path>
            </g>
            <path
              fill="#fff"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              d="M298.758 225.188h36.099v94.117h-36.099z"
            ></path>
            <g transform="translate(338.736 348.797)">
              <path
                fill="#fff"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c0-6.334-2.107-11.316-6.297-14.923-4.2-3.608-9.398-5.408-15.578-5.408-6.179 0-11.378 1.8-15.577 5.408C-41.659-11.316-43.75-6.334-43.75 0c0 6.47 2.091 11.522 6.298 15.122 4.199 3.617 9.398 5.426 15.577 5.426 6.18 0 11.378-1.809 15.578-5.426C-2.107 11.522 0 6.47 0 0"
              ></path>
            </g>
            <g transform="translate(786.294 312.243)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0h-4.19v2.936H7.744V0H3.499v-12.587H0z"
              ></path>
            </g>
            <g transform="translate(808.924 305.618)">
              <path
                fill="#006b71"
                fillOpacity="1"
                fillRule="nonzero"
                stroke="none"
                d="M0 0c-.073 1.854-.146 4.09-.146 6.379h-.027A106.118 106.118 0 00-1.918.364l-1.909-6.053h-2.727L-8.216.291a85.222 85.222 0 00-1.4 6.088h-.026c-.1-2.099-.193-4.506-.274-6.434l-.299-5.898h-3.236l.972 15.523h4.681l1.527-5.199a68.96 68.96 0 001.309-5.524h.082c.417 1.789.935 3.815 1.49 5.57l1.618 5.153h4.617l.817-15.523H.256L0 0"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
